<template>
  <div class="arrows">
    <div class="arrowi first"></div>
    <div class="arrowi upsideDown"></div>
  </div>
</template>
<script>
export default {
  name: 'VerticalDragArrows',
};
</script>
<style lang="scss" scoped>
.first {
  margin-bottom: 10px;
}

.arrowi {
  border-bottom: 15px solid #646464;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  width: 0px;
  height: 0px;

  &.upsideDown {
    transform: rotate(180deg);
  }
}
</style>